import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { ContactDetail, HomePageSection } from "../../types/types";
import { richTextRenderOptions } from "../../utils/helpers";
// import { ContactForm } from "../forms/ContactForm";

interface ContactSectionProps {
  showModal(): void;
  content?: HomePageSection;
  contactDetails: ContactDetail[];
}

export const ContactSection = ({
  showModal,
  content,
  contactDetails,
}: ContactSectionProps) => {
  const phone = contactDetails.find((entry) => entry.name === "Phone");
  const email = contactDetails.find((entry) => entry.name === "Email");
  const addressLine1 = contactDetails.find(
    (entry) => entry.name === "AddressLine1"
  );
  const addressLine2 = contactDetails.find(
    (entry) => entry.name === "AddressLine2"
  );

  return (
    <div className="relative bg-gray-50" id="contact">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-5 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-3xl secondary-header font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              {content?.title}
            </h2>
            <div className="mt-3 text-lg leading-6 text-gray-500">
              {content &&
                documentToReactComponents(
                  content.body.json,
                  richTextRenderOptions
                )}
            </div>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Address</dt>
                <dd>
                  <p>{addressLine1 && addressLine1.value}</p>
                  <p>{addressLine2 && addressLine2.value}</p>
                </dd>
              </div>
              {phone && (
                <div className="mt-6">
                  <dt className="sr-only">Phone number</dt>
                  <dd className="flex">
                    <svg
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                    <span className="ml-3">{phone.value}</span>
                  </dd>
                </div>
              )}
              {email && (
                <div className="mt-3">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <svg
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                    <span className="ml-3">{email.value}</span>
                  </dd>
                </div>
              )}
            </dl>
            <p className="mt-6 text-base text-gray-500">
              Ready to get started?
              <b
                // onClick={showModal}
                className="font-medium text-gray-700 ml-2"
              >
                Contact us today to book a free trial lesson
              </b>
              .
            </p>
          </div>
        </div>
        {/* <ContactForm />*/}
      </div>
    </div>
  );
};
